<template>
  <div>
    <!--导航栏--->
    <div style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        right: 0px;
        z-index: 10;
      ">
      <van-nav-bar title="购物车" right-text="管理" left-arrow @click-left="$router.back(-1)" />
    </div>
    <!--导航栏--->
    <div class="wrap" style="background-color: #ffffff;border-radius: 10px;">
      <div class="items">
        <div style="display: flex;">
          <div><van-checkbox style="margin-top: 10px;margin-left: 10px" checked-color="#ff6100" v-model="checked" />
          </div>
          <div style="margin-left: 20px;margin-top: 10px;">曼斯菲儿旗舰店</div>
        </div>
        <div class="111" style="display: flex;padding: 10px;border-bottom: 1px solid #eeeeee;">
          <div class="checkeds"><van-checkbox style="" checked-color="#ff6100" v-model="checked" /> </div>
          <div><van-image width="5rem" height="5rem" src="https://img01.yzcdn.cn/vant/cat.jpeg" /></div>
          <div style="display: flex; flex-direction: column;margin-left: 10px;">
            <div>
              <div>厨房职务加收纳用品家用太多厨房职务加收纳用品家用太多厨房职务加收</div>
              <div><van-button color="#eeeeee" size="mini">白色复杂</van-button></div>
            </div>
            <div style="display: flex;justify-content: space-between; margin-top: 12px;">
              <div style="    color: #ae0c0c; font-weight: bold; font-size: 18px;">￥48.28</div>
              <div><van-stepper v-model="value" /></div>
            </div>
          </div>
        </div>
        <div class="111" style="display: flex;padding: 10px;">
          <div class="checkeds"><van-checkbox style="" checked-color="#ff6100" v-model="checked" /> </div>
          <div><van-image width="5rem" height="5rem" src="https://img01.yzcdn.cn/vant/cat.jpeg" /></div>
          <div style="display: flex; flex-direction: column;margin-left: 10px;">
            <div>
              <div>厨房职务加收纳用品家用太多厨房职务加收纳用品家用太多厨房职务加收</div>
              <div><van-button color="#eeeeee" size="mini">白色复杂</van-button></div>
            </div>
            <div style="display: flex;justify-content: space-between; margin-top: 12px;">
              <div style="    color: #ae0c0c; font-weight: bold; font-size: 18px;">￥48.28</div>
              <div><van-stepper v-model="value" /></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部 -->

      <van-submit-bar :price="3050" button-color="rgb(255, 114, 0)" button-text="结算"
        @submit="$router.push('/sureorder')">
        <van-checkbox checked-color="#f1f1f1" v-model="checked">全选</van-checkbox>
      </van-submit-bar>
      <!-- 底部 -->
    </div>
    <!-- 失效商品 -->
    <div class="123" style="position: fixed;left: 0;bottom: 55px;right: 0;">
      <div style="padding: 15px;">
        <van-row type="flex" justify="space-between">
          <van-col span="6">失效商品1件</van-col>
          <van-col span="6" style="color:red">清空失效商品</van-col>
        </van-row>
      </div>
      <div>
        <div class="111" style="display: flex;padding: 10px;border-bottom: 1px solid #eeeeee;">
          <div class="checked">失效</div>
          <div><van-image width="5rem" height="5rem" src="https://img01.yzcdn.cn/vant/cat.jpeg" /></div>
          <div style="display: flex; flex-direction: column;margin-left: 10px;">
            <div>
              <div>厨房职务加收纳用品家用太多厨房职务加收纳用品家用太多厨房职务加收</div>
            </div>
            <div style="display: flex;justify-content: space-between; margin-top: 12px;">
              <div style=" color:#b3b3b3; font-weight: bold;">宝贝已失效，不能购买</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShopping } from "@/api/proprietary/proprietary";

export default {
  data() {
    return {
      id: "",
      value: 1,
      checked: true,
      shops: {},
    };
  },
  created() {
    this.getShoop()
  },
  mounted() { },
  methods: {
    //获取数据
    getShoop() {
      getShopping({}).then((res) => {
        if (res.data.code === 0) {
          this.shops = res.data.data;
          console.log(res);
          console.log(this.id);
        }
      });
    },
    onClickLeft() { },
  },
  watch: {},
  computed: {},
};
</script>
<style >
.van-submit-bar__price {
  font-size: 16px;
}

.van-nav-bar .van-icon {
  color: black;

}

.van-nav-bar__text {
  color: black;
}

.checkeds {
  margin-top: 20px;
  margin-right: 20px;
}

.checked {
  margin-top: 20px;
  margin-right: 20px;
}

.item {
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid #d9d6d9;
}

.items {
  margin-top: 60px;


}
</style>